import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import FloatingButton from '../../component/floatingButton/FloatingButton';
import { Check } from 'react-bootstrap-icons';
import './Donne.css';

const Donne = () => {
  const { id } = useParams();

  // Prévoir une énumération pour les contrats
  // Petite, Garde, Garde sans, Garde contre
  // Et pour les annonces : 
  // Misère de tête, Misère d'atouts, Poignée (simple, double, triple), Chelem, Chelem annoncé, Petit au bout

  // Liste des joueurs (hardcodée pour cet exemple)
  const players = ['Alice', 'Bob', 'Charlie', 'Antoine', 'Albert'];

  const contrats = ['Petite', 'Garde', 'Garde sans', 'Garde contre'];

  const annonces = {
    'Misère de tête': 10,
    'Misère d\'atouts': 20,
    'Poignée simple': 20,
    'Poignée double': 30,
    'Poignée triple': 40,
    'Chelem': 400,
    'Chelem annoncé': 200,
  }

  const bonus = {
    'Petit au bout': 10
  }

  const bouts = ['Petit', '21', 'Excuse'];
  
  const couleurs = ['Pique', 'Coeur', 'Carreau', 'Trèfle'];

  // État pour les scores
  const [scores, setScores] = useState(players.reduce((acc, player) => {
    acc[player] = '';
    return acc;
  }, {}));

  // Gestion de la soumission du formulaire
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Scores:', scores);
    // Ajoutez ici la logique pour enregistrer les scores
  };

  // Gestion du changement de score
  const handleChange = (event, player) => {
    setScores({
      ...scores,
      [player]: event.target.value,
    });
  };

  return (
    <div>
      <div className='container p-4'>
        <form onSubmit={handleSubmit}>

            <div className='row'>
              <div className='col input-bloc'>
                <label>
                  Joueur parti :
                </label>
                <select>
                  {players.map(player => (
                    <option value={player}>{player}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className='row'>
                <div className='col input-bloc'>
                    <label>
                    Contrat :
                    </label>
                    <select>
                        {contrats.map(contrat => (
                            <option value={contrat}>{contrat}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='row'>
              <div className='col input-bloc'>
                <label>
                  Couleur appelée :
                </label>
                <select>
                  {couleurs.map(couleur => (
                    <option value={couleur}>{couleur}</option>
                  ))}
                  </select>
              </div>
            </div>

            <div className='row'>
              <div className='col input-bloc'>
                <label>
                  Joueur appelé :
                </label>
                <select>
                  {players.map(player => (
                    <option value={player}>{player}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className='row'>
              <div className='col input-bloc'>
                <label>
                  Bouts : 
                </label>
                <div className='row'>
                  {bouts.map(bout => (
                    <div className='col input-bloc'>
                      <label>{bout}</label>
                      <input type="checkbox" value={bout}></input>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            

            <div className='row'>
              <div className='col input-bloc'>
                <label htmlFor="customRange1" className="form-label">Points réalisés</label>
              </div>
              <div className='row'>
                <div className='col-auto input-bloc'>
                  <label>Défense</label>
                  <input type="number" value="0"></input>
                </div>
                <div className='col my-auto'>
                  <input type="range" className="form-range" id="customRange1"></input>
                </div>
                <div className='col-auto input-bloc'>
                  <label>Attaque</label>
                  <input type="number" value="0"></input>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label>Petit au bout :</label>
                <input type="checkbox" value="true"></input>
              </div>
            </div>

            <FloatingButton onClick={handleSubmit} Icon={Check} isSubmit={true} />
        </form>
        
      </div>
    </div>
  );
};

export default Donne;