import React from 'react';
import PropTypes from 'prop-types';
import './FloatingButton.css';

const FloatingButton = ({ onClick, Icon, isSubmit = false }) => (
    <button id="floatingButton" className="floating-button" onClick={onClick} type={isSubmit? 'submit' : 'button'}>
        <div className="button-content">
            <Icon />
        </div>
    </button>
);

FloatingButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    Icon: PropTypes.elementType.isRequired
};

export default FloatingButton;
