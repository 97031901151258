import worker from '../../images/worker.gif';


const Progress = () => {
    return (
        <section>
            <h1 className='wip-title'>Work In <span>Progress</span></h1>
            <img className='work-in-progress' src={worker} alt="Work in progress"/>
        </section>
    );
}

export default Progress;