import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { Plus } from 'react-bootstrap-icons';
import FloatingButton from '../../component/floatingButton/FloatingButton';
import './Game.css';


const Game = () => {
    const gameSample = {
  id: 1,
  creationDate: new Date().toISOString(),
  players: [
    'Alice','Bob', 'Charlie', 'Antoine', 'Albert'
  ],
  donnes: [
    {
      id: 1,
      scores: [
        { player: 'Alice', points: 10 },
        { player: 'Bob', points: -10 },
        { player: 'Charlie', points: 0 },
        { player: 'Albert', points: 15 },
        { player: 'Antoine', points: -15 },
      ],
    },
    {
      id: 2,
      scores: [
        { player: 'Alice', points: -5 },
        { player: 'Bob', points: 5 },
        { player: 'Charlie', points: 0 },
        { player: 'Antoine', points: 0 },
        { player: 'Albert', points: 0 },
      ],
    },
    {
      id: 3,
      scores: [
        { player: 'Alice', points: 0 },
        { player: 'Bob', points: 0 },
        { player: 'Charlie', points: 15 },
        { player: 'Antoine', points: -15 },
        { player: 'Albert', points: 0 },
      ],
    },
    {
      id: 4,
      scores: [
        { player: 'Alice', points: 20 },
        { player: 'Bob', points: -10 },
        { player: 'Charlie', points: -10 },
        { player: 'Antoine', points: 0 },
        { player: 'Albert', points: 0 },
      ],
    },
    {
      id: 5,
      scores: [
        { player: 'Alice', points: -10 },
        { player: 'Bob', points: 10 },
        { player: 'Charlie', points: 0 },
        { player: 'Antoine', points: 0 },
        { player: 'Albert', points: 0 },
      ],
    },
  ],
};


    const {id} = useParams();
    const navigate = useNavigate();

    const handleNavigateToDonne = () => {
        navigate(`/donne/${id}`);
    }

    const totals = gameSample.players.reduce((acc, player) => {
        acc[player] = gameSample.donnes.reduce((sum, donne) => {
        const score = donne.scores.find(score => score.player === player);
        return sum + (score ? score.points : 0);
        }, 0);
        return acc;
    }, {});

    return (
        <div className="container">
            <div className="table-container">
                <table className='table-game'>
                <thead>
                    <tr>
                    <th>Donne</th>
                    {gameSample.players.map(player => (
                    <th key={player}>{player}</th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    <tr className="total-row">
                        <td className='index-donne'>Total</td>
                            {gameSample.players.map(player => {
                            const isHighest = totals[player] === Math.max(...Object.values(totals));
                            return (
                                <td
                                key={player}
                                className={`score ${isHighest ? 'highest' : totals[player] < 0 ? 'negative' : 'positive'}`}
                                >
                                {totals[player]}
                                </td>
                            );
                            })}
                    </tr>
                    {gameSample.donnes.map(donne => (
                        <tr key={donne.id}>
                        <td className='index-donne'>{donne.id}</td>
                        {gameSample.players.map(player => {
                            const score = donne.scores.find(score => score.player === player);
                            return (
                                <td
                                key={player}
                                className={`score ${score && score.points < 0 ? 'negative' : 'positive'}`}
                                >
                                {score ? score.points : '-'}
                                </td>
                            );
                            })}
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
            
            <FloatingButton onClick={handleNavigateToDonne} Icon={Plus} />
        </div>
    );
};

export default Game;