import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home = function() {
    const navigate = useNavigate();

    const handleNavigate = () => {
        const gameId = 1; // Remplacez par l'ID de la partie que vous souhaitez
        navigate(`/game/${gameId}`);
    };

    return (
        <div>
            <h1>Home Page</h1>
            <button onClick={handleNavigate}>Go to Game</button>
        </div>
    );
};

export default Home;