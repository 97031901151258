import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Game from './pages/Game/Game';
import './App.css';
import Navbar from './component/navbar/navbar';
import Donne from './pages/Donne/Donne';
import Home from './pages/HomePage/Home';
import Progress from './pages/Progress/Progress';

const App = () => {
  return (
    <Router>
      <div className="App">
        {/* <Navbar/> */}
        <Routes>
          <Route path="/" element={<Progress/> } />
          <Route path="/home" element={<Home/>} />
          <Route path="/game/:id" element={<Game id />} />
          <Route path="/donne/:id" element={<Donne/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;